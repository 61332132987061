<template>
  <div id="bottomRight">
    <div class="bg-color-black">
      <!-- 标题 -->
      <div class="header">
        <div class="header-line" style="margin-bottom: 2px"></div>
        <div class="header-title">任务状态</div>
        <div class="header-line" style="margin-top: 2px"></div>
      </div>

      <div class="content">
        <div class="content-top">
          <!-- 数据box -->
          <div class="data-box">
            <div class="label">近期用户</div>
            <div class="value">{{ userNum }}</div>
          </div>
          <div class="data-box">
            <div class="label">近期任务</div>
            <div class="value">{{ taskNum }}</div>
          </div>

          <!-- 折线柱状图 -->
          <div class="d-flex jc-center">
            <BottomLineBarChart @setNum="setNum" />
          </div>
        </div>
        <div class="content-bottom">
          <!-- 节点 -->
          <dv-scroll-board
            v-if="nodeConfig.data.length > 0"
            :config="nodeConfig"
            style="width: 100%; height: 172px"
          />
        </div>
      </div>
    </div>
  </div>
</template>
    
    <script>
// 柱状图
import BottomLineBarChart from "@/components/echart/detail/bottomRight/bottomLineBarChart";
import { getTaskDetail } from "@/common/api/index.js";

export default {
  components: {
    BottomLineBarChart,
  },
  data() {
    return {
      userNum: 0,
      taskNum: 0,
      nodeConfig: {
        header: [
          "作业ID", //jobid
          "作业名", //name
          "用户名", //user
          "状态", // stat
          "资源",
          "执行节点",
          "运行时间",
          "分区",
        ], // 表头title AI
        // header: [
        //   "作业ID", //jobid
        //   "作业名", //name
        //   "用户名", //user
        //   "状态", // stat
        //   "分区",
        //   "提交节点",
        //   "执行节点",
        // ], // 表头title AT
        data: [],
        headerBGC: "rgba(106,112,124,0.2)", //表头颜色
        evenRowBGC: "", //偶数列背景色
        oddRowBGC: "", // 基数列背景色
        // columnWidth: [166.5, 80, 90, 70, 70, 100, 146.5], // 每列宽度 AT
        columnWidth: [80, 80, 90, 86.5, 70, 100, 100, 116.5], // 每列宽度 AI
        align: [
          "center",
          "center",
          "center",
          "center",
          "center",
          "center",
          "center",
          "center",
        ],
        waitTime: "3000", // 轮播间隔
        rowNum: 4, //展示的行数
      },
    };
  },

  mounted() {
    const { cluster } = this.$route.query;

    // 特殊处理表头数据结构
    if (cluster == "AT") {
      this.nodeConfig.header = [
        "作业ID", //jobid
        "作业名", //name
        "用户名", //user
        "状态", // stat
        "分区",
        "提交节点",
        "执行节点",
      ];
      this.nodeConfig.columnWidth = [166.5, 80, 90, 70, 70, 100, 146.5]; // 每列宽度 AT
    } else {
      this.nodeConfig.header = [
        "作业ID", //jobid
        "作业名", //name
        "用户名", //user
        "状态", // stat
        "资源",
        "执行节点",
        "运行时间",
        "分区",
      ];
      this.nodeConfig.columnWidth = [80, 80, 90, 86.5, 70, 100, 100, 116.5]; // 每列宽度 AI

    }
    this.drawTimingFn();

  },
  beforeDestroy() {
    clearInterval(this.drawTiming);
  },
  methods: {
    setNum(user, task) {
      this.userNum = user;
      this.taskNum = task;
    },
    drawTimingFn() {
      this.setData();
      this.drawTiming = setInterval(() => {
        this.setData();
      }, this.$requestInterval);
    },
    setData() {
      const { cluster } = this.$route.query;
      console.log('cluster 8888888888888',cluster)
      getTaskDetail(cluster)
        .then((response) => {
          const list = response.data.list || [];
          // 区分 AI 与 AT
          let result;
          if (cluster === "AT") {
            //AT
            result = list.map((obj) => [
              obj.jobid,
              obj.name,
              obj.user,
              obj.stat,
              obj.queue,
              obj.fromHost,
              obj.execHost,
            ]);
          } else {
            result = list.map((obj) => [
              obj.jobid,
              obj.name,
              obj.user,
              obj.state,
              obj.tresPerNode,
              obj.execHost,
              obj.time,
              obj.partitionInfo,
            ]);
          }
          this.$nextTick(() => {
            this.nodeConfig.data = result;
          });
          this.$forceUpdate();
        })
        .catch((error) => {
          console.error("There was an error!", error);
          // 处理错误
        });
    },
  },
};
</script>
    
  <style lang="scss" scoped>
$box-width: 755px;
$box-height: 380px;

#bottomRight {
  padding: 6px;
  height: $box-height;
  width: $box-width;
  border-radius: 10px;
  position: relative;

  .header {
    font-family: YouSheBiaoTiHei, YouSheBiaoTiHei;
    padding: 16px 0;
    .header-line {
      width: 100%;
      height: 1px;
      background: linear-gradient(
        90deg,
        rgba(0, 152, 235, 0) 12%,
        #0098eb 52%,
        rgba(0, 152, 235, 0) 83%
      );
    }
    .header-title {
      color: #eff4ff;
      background: linear-gradient(
        90deg,
        rgba(0, 152, 235, 0) 0%,
        rgba(0, 152, 235, 0.5) 53%,
        rgba(0, 152, 235, 0) 100%
      );
      width: 100%;
      height: 36px;
      // margin: 20px 0;
      text-align: center;
      line-height: 36px;
      font-size: 22px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 0 16px;

    .content-top {
      display: flex;
      align-items: center;
    }

    .content-bottom {
      margin-top: 20px;
    }
    .data-box {
      margin-right: 16px;
      width: 94px;
      height: 98px;
      background: linear-gradient(
        rgba(8, 20, 37, 0.54) 0%,
        rgba(47, 61, 82, 0.09) 100%
      );
      box-shadow: inset 0px 5px 20px 1px rgba(43, 46, 52, 0.56);
      border-radius: 6px 6px 6px 6px;
      border: 1px solid rgba(65, 146, 217, 1);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .label {
        font-weight: 500;
        font-size: 12px;
        color: #ffffff;
        text-align: center;
      }
      .value {
        margin-top: 12px;
        font-family: DIN, DIN;
        font-weight: 500;
        font-size: 40px;
        color: #ffffff;
      }
    }
  }

  .bg-color-black {
    height: $box-height - 12px;
    width: $box-width;
    border-radius: 10px;
  }
}
</style>
    