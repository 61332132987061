<template>
  <div>
    <Echart
      :options="options"
      id="bottomBarChart"
      height="98px"
      width="520px"
    ></Echart>
  </div>
</template>
  
  <script>
import Echart from "@/common/echart";
export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        this.options = {
        
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              crossStyle: {
                color: "#999",
              },
            },
          },

          grid: {
            left: 0,
            right: 56,
            bottom:12,
            top: 12,
          },
          xAxis: {
            type: "value",
            // max: 100,
            // data:[0,20,40,60,80,100]
            // splitNumber: 5
            axisTick: {
              // 轴刻度线
              show: false,
            },
            axisLine: {
              // 轴
              show: false,
            },
            splitLine: {
              show: false,
            },

            axisLabel: {
              show: false
            },

          },
          // 下方Y轴
          yAxis: {
            type: "category",
            data: [""],
            axisTick: {
              // 轴刻度线
              show: false,
            },
            axisLine: {
              // 轴
              show: false,
            },
            splitLine: {
              show: false,
            },

            axisLabel: {
              show: false
            },
           
          },
          legend: {
            // data: ["CPU", "MEM", "GPU"],
            orient:'vertical',
            right: 0,
            top: 12,
            textStyle: {
              fontSize: 12,
              color: (params) => {
                // 假设你的series数组中存储了颜色信息
                const series = this.options.series.find(
                  (s) => s.name === params.name
                );
                return series ? series.lineStyle.color : "red";
              },
            },
            icon: "roundRect", // 图例icon
            itemGap: 16,
            itemWidth: 8,
            itemHeight: 3,
          },

          series: [
            {
              name: "空闲",
              type: "bar",
              stack: "total",
              label: {
                show: (params) => params.value > 0,
                formatter: (params) => params.value>0?params.value + "%":'',
                textStyle: {
                  fontSize: 16,
                  fontWeight: 600,
                  color: "#fff",
                },
              },
              emphasis: {
                focus: "series",
              },

              itemStyle: {
                normal: {
                  color: "rgba(61, 237, 199, 1)",
                },
              },
              data:newData.alloc,
            },
            {
              name: "占用",
              type: "bar",
              stack: "total",
              label: {
                show: (params) => params.value > 0,
                formatter: (params) => params.value>0?params.value + "%":'',
                textStyle: {
                  fontSize: 16,
                  fontWeight: 600,
                  color: "#fff",
                },
              },
              emphasis: {
                focus: "series",
              },
              itemStyle: {
                normal: {
                  color: "rgba(49, 181, 237, 1)",
                },
              },
              data:newData.ok,
            },
            {
              name: "故障",
              type: "bar",
              stack: "total",
              label: {
                show: (params) => params.value > 0,
                formatter: (params) => params.value>0?params.value + "%":'',
                textStyle: {
                  fontSize: 16,
                  fontWeight: 600,
                  color: "#fff",
                },
              },
              emphasis: {
                focus: "series",
              },
              itemStyle: {
                normal: {
                  color: "rgba(240, 70, 71, 1)",
                },
              },
              data:newData.fail,
            },
            // {
            //   name: "其他",
            //   type: "bar",
            //   stack: "total",
            //   label: {
            //     show: (params) => params.value > 0,
            //     formatter: (params) => params.value>0?params.value + "%":'',
            //     textStyle: {
            //       fontSize: 16,
            //       fontWeight: 600,
            //       color: "#fff",
            //     },
            //   },
            //   emphasis: {
            //     focus: "series",
            //   },
            //   itemStyle: {
            //     normal: {
            //       color: "#999",
            //     },
            //   },
            //   data: newData.other,
            // },
          ],
        };
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
  