<template>
  <div>
    <Echart
      :options="options"
      id="centreBarChart"
      height="220px"
      width="755px"
    ></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        this.options = {
          tooltip: {
            trigger: "item",
          },

          grid: {
            left: 16,
            right: 16,
            bottom: 8,
            top: 36,
            containLabel:true
          },
          xAxis: {
            type: "category",
            position: "bottom",
            data: ["CPU", "MEM", "GPU", "存储"],
            axisLine: true,
            axisLabel: {
              color: "#fff",
              fontSize: 12,
              // fontWeight: 600,
            },
          },
          // 下方Y轴
          yAxis: {
            type: "value",
            nameLocation: "end",
            nameGap: 24,
            nameTextStyle: {
              color: "#fff",
              fontSize: 14,
            },
            max: 100,
            splitNumber: 5,

            axisLine: {
              lineStyle: {
                opacity: 0,
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#fff",
                opacity: 0.1,
              },
            },
            axisLabel: {
              color: "#fff",
              fontSize: 12,
            },
          },
          series: [
            {
              type: "bar",
              label: {
                show: true,
                formatter: "{c} %",
                position: "top",
              },
              showBackground: true,
              backgroundStyle: {
                color: "rgba(180, 180, 180, 0.2)",
              },
              barWidth:120,
              data: [
                {
                  value: newData.cpu,
                  itemStyle: {
                    color: "rgba(49, 126, 234, 1)",
                  },
                },
                {
                  value: newData.mem,
                  itemStyle: {
                    color: "rgba(66, 244, 183, 1)",
                  },
                },
                {
                  value: newData.gpu,
                  itemStyle: {
                    color: "rgba(255, 208, 59, 1)",
                  },
                },
                {
                  value: newData.df,
                  itemStyle: {
                    color: "rgba(218, 34, 255, 1)",
                  },
                },
              ],
              lineSmooth: true,

              tooltip: {
                position: "top",
                formatter: "{c} %",
                backgroundColor: "rgba(28,152,232,.2)",
                padding: 6,
              },
            },
          ],
        };
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
