<template>
  <div class="d-flex jc-center">
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from "./chart.vue";

import { getRealTimeUtilisation } from "@/common/api/index.js";

export default {
  data() {
    return {
      drawTiming: null,
      cdata: {},
    };
  },
  components: {
    Chart,
  },
  mounted() {
    this.drawTimingFn();
  },
  beforeDestroy() {
    clearInterval(this.drawTiming);
  },
  methods: {
    drawTimingFn() {
      this.setData();
      this.drawTiming = setInterval(() => {
        this.setData();
      }, this.$requestInterval);
    },
    setData() {
      // 实时利用率
      const params = {
        cluster: this.$route.query.cluster,
      };
      getRealTimeUtilisation(params)
        .then((response) => {
          this.cdata = response.data;
        })
        .catch((error) => {
          console.error("There was an error!", error);
          // 处理错误
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>