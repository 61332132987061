<template>
  <div id="centerLeft">
    <div class="bg-color-black">
      <!-- 标题 -->
      <div class="header">
        <div class="header-line" style="margin-bottom: 2px"></div>
        <div class="header-title">整体资源概况</div>
        <div class="header-line" style="margin-top: 2px"></div>
      </div>

      <!-- 柱状图 -->
      <div class="bar-chart">
        <div class="title">
          <div class="play-icon"></div>
          <span>实时利用率</span>
        </div>
        <div class="d-flex jc-center">
          <CenterBarChart  />
        </div>
      </div>
      <!-- 折线图 -->
      <div class="line-chart">
        <div class="title">
          <div class="play-icon"></div>
          <span>历史利用率</span>
        </div>
        <div class="d-flex jc-center">
          <CenterLineChart />
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import CenterBarChart from "@/components/echart/detail/centerLeft/centerBarChart";
import CenterLineChart from "@/components/echart/detail/centerLeft/centerLineChart";
export default {
  data() {
    return {};
  },
  components: {
    CenterBarChart,
    CenterLineChart,
  },
  mounted() {},
  methods: {},
};
</script>
  
<style lang="scss" scoped>
$box-width: 100%;
$box-height: 568px;

#centerLeft {
  padding: 6px;
  height: $box-height;
  width: $box-width;
  border-radius: 10px;
  position: relative;

  .header {
    font-family: YouSheBiaoTiHei, YouSheBiaoTiHei;
    padding: 16px 0;
    .header-line {
      width: 100%;
      height: 1px;
      background: linear-gradient(
        90deg,
        rgba(0, 152, 235, 0) 12%,
        #0098eb 52%,
        rgba(0, 152, 235, 0) 83%
      );
    }
    .header-title {
      color: #eff4ff;
      background: linear-gradient(
        90deg,
        rgba(0, 152, 235, 0) 0%,
        rgba(0, 152, 235, 0.5) 53%,
        rgba(0, 152, 235, 0) 100%
      );
      width: 100%;
      height: 36px;
      // margin: 20px 0;
      text-align: center;
      line-height: 36px;
      font-size: 22px;
    }
  }

  .bg-color-black {
    height: $box-height - 12px;
    width: $box-width;
    border-radius: 10px;
  }

  .line-chart,
  .bar-chart {
    position: relative;
    .title {
      position: absolute;
      top: 8px;
      left: 16px;
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      display: flex;
      align-items: center;
      .play-icon {
        margin-right: 8px;
        position: relative;
        top: -2px;
        width: 0;
        height: 0;
        border-radius: 2px;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 9px solid #ffd03b;
        transform: rotate(270deg);
      }
    }
  }

  .bar-chart {
    .title {
      top: 0;
    }
  }
}
</style>
  