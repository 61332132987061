<template>
  <div id="centerRight">
    <div class="bg-color-black">
      <!-- 标题 -->
      <div class="header">
        <div class="header-line" style="margin-bottom: 2px"></div>
        <div class="header-title">节点资源概况</div>
        <div class="header-line" style="margin-top: 2px"></div>
      </div>

      <div class="content">
        <!-- 折线图 -->
        <div class="content-left">
          <!-- 折线图 -->
          <div class="line-chart">
            <div class="title">
              <div class="play-icon"></div>
              <span>节点CPU利用率</span>
            </div>

            <CenterLineChart1 />
          </div>
          <div class="line-chart">
            <div class="title">
              <div class="play-icon"></div>
              <span>节点内存利用率</span>
            </div>
            <CenterLineChart2 />
          </div>
          <div class="line-chart">
            <div class="title">
              <div class="play-icon"></div>
              <span>节点GPU利用率</span>
            </div>
            <CenterLineChart3 />
          </div>
        </div>
        <!-- 右侧节点滚动 -->
        <div class="content-right">
          <div class="node-item" v-for="(item, index) in nodeData" :key="index">
            <div class="filter-header">
              <div class="itemx">
                <span> Max </span>
                <i class="iconfont icon-down"></i>
              </div>

              <div class="itemx">
                <span> Avg </span>
                <i class="iconfont icon-down"></i>
              </div>
              <div class="itemx">
                <span> Current </span>
                <i class="iconfont icon-down"></i>
              </div>
            </div>
            <dv-scroll-board
              v-if="item.data.length > 0"
              :config="item"
              @click="handleClick"
              style="width: 100%; height: 120px"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import CenterLineChart1 from "@/components/echart/detail/centerRight/centerLineChart1";
import CenterLineChart2 from "@/components/echart/detail/centerRight/centerLineChart2";
import CenterLineChart3 from "@/components/echart/detail/centerRight/centerLineChart3";
import {
  getTaskDetialCPUMore,
  getTaskDetialMEMMore,
  getTaskDetialGPUMore,
} from "@/common/api/index.js";

export default {
  data() {
    return {
      nodeData: [
        {
          header: [], // 表头title
          data: [],
          headerBGC: "", //表头颜色
          evenRowBGC: "", //偶数列背景色
          oddRowBGC: "#1F3450", // 基数列背景色
          columnWidth: [228, 60, 60, 60], // 每列宽度
          align: ["left", "center", "center", "center"],
          waitTime: "3000", // 轮播间隔
          rowNum: 5, //展示的行数
          carousel:'page'
        },
        {
          header: [], // 表头title
          data: [],
          headerBGC: "", //表头颜色
          evenRowBGC: "", //偶数列背景色
          oddRowBGC: "#1F3450", // 基数列背景色
          columnWidth: [228, 60, 60, 60], // 每列宽度
          align: ["left", "center", "center", "center"],
          waitTime: "3000", // 轮播间隔
          rowNum: 5, //展示的行数
          carousel:'page'

        },
        {
          header: [], // 表头title
          data: [],
          headerBGC: "", //表头颜色
          evenRowBGC: "", //偶数列背景色
          oddRowBGC: "#1F3450", // 基数列背景色
          columnWidth: [228, 60, 60, 60], // 每列宽度
          align: ["left", "center", "center", "center"],
          waitTime: "3000", // 轮播间隔
          rowNum: 5, //展示的行数
          carousel:'page'
        },
      ],
    };
  },
  components: {
    CenterLineChart1,
    CenterLineChart2,
    CenterLineChart3,
  },
  mounted() {
    this.drawTimingFn();
  },
  beforeDestroy() {
    clearInterval(this.drawTiming);
  },
  methods: {
    // 轮播点击
    handleClick(e){
      console.log('1111111',e)
      // 设置

    },
    drawTimingFn() {
      this.setData();
      this.drawTiming = setInterval(() => {
        this.setData();
      }, this.$requestInterval);
    },

    setData() {
      const params = {
        cluster: this.$route.query.cluster,
      };
      getTaskDetialCPUMore(params)
        .then((response) => {
          const list = response.data.list || [];

          const result = list.map((obj) => [
            obj.hostName,
            obj.Max,
            obj.Avg,
            obj.Current,
          ]);
          this.nodeData[0].data = result;
        })
        .catch((error) => {
          console.error("There was an error!", error);
          // 处理错误
        });
      getTaskDetialMEMMore(params)
        .then((response) => {
          const list = response.data.list || [];
         
          const result = list.map((obj) => [
            obj.hostName,
            obj.Max,
            obj.Avg,
            obj.Current,
          ]);
          this.nodeData[1].data = result;
        })
        .catch((error) => {
          console.error("There was an error!", error);
          // 处理错误
        });
      getTaskDetialGPUMore(params)
        .then((response) => {
          const list = response.data.list || [];
          
          const result = list.map((obj) => [
            obj.hostName,
            obj.Max,
            obj.Avg,
            obj.Current,
          ]);
          this.nodeData[2].data = result;
        })
        .catch((error) => {
          console.error("There was an error!", error);
          // 处理错误
        });
    },
  },
};
</script>
  
  <style lang="scss" scoped>
$box-width: 100%;
$box-height: 568px;

#centerRight {
  padding: 6px;
  height: $box-height;
  width: $box-width;
  border-radius: 10px;
  position: relative;
  .bg-color-black {
    height: $box-height - 12px;
    width: $box-width;
    border-radius: 10px;
  }
  .header {
    font-family: YouSheBiaoTiHei, YouSheBiaoTiHei;
    padding: 16px 0;
    .header-line {
      width: 100%;
      height: 1px;
      background: linear-gradient(
        90deg,
        rgba(0, 152, 235, 0) 12%,
        #0098eb 52%,
        rgba(0, 152, 235, 0) 83%
      );
    }
    .header-title {
      color: #eff4ff;
      background: linear-gradient(
        90deg,
        rgba(0, 152, 235, 0) 0%,
        rgba(0, 152, 235, 0.5) 53%,
        rgba(0, 152, 235, 0) 100%
      );
      width: 100%;
      height: 36px;
      // margin: 20px 0;
      text-align: center;
      line-height: 36px;
      font-size: 22px;
    }
  }
  .content {
    display: flex;
    justify-content: space-between;
    .content-left {
    }
    .content-right {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-items: center;
      width: 100%;
      padding-top: 6px;
      .node-item {
        margin-bottom: 23px;
        width: 100%;
        height: 135.59px;
        ::v-deep .row-item {
          font-size: 12px;

          &:hover {
            background-color: #0098eb !important;
          }
        }

        .filter-header {
          font-family: Arial, Helvetica, sans-serif;
          color: #ffd03b;
          font-weight: bold;
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .itemx {
            width: 60px;
            text-align: center;

            .iconfont {
              // margin-top: 3px;
              color: #ffd03b;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  .line-chart {
    width: 690px;
    .title {
      margin-left: 16px;
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      display: flex;
      align-items: center;
      .play-icon {
        margin-right: 8px;
        position: relative;
        top: -2px;
        width: 0;
        height: 0;
        border-radius: 2px;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 9px solid #ffd03b;
        transform: rotate(270deg);
      }
    }
  }
}
</style>
  