<template>
  <div class="d-flex jc-center">
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from "./chart.vue";

import { getTaskDetailGPU } from "@/common/api/index.js";

export default {
  data() {
    return {
      drawTiming: null,
      cdata: {
        x: [],
        series: [],
      },
    };
  },
  components: {
    Chart,
  },
  mounted() {
    this.drawTimingFn();
  },
  beforeDestroy() {
    clearInterval(this.drawTiming);
  },
  methods: {
    drawTimingFn() {
      this.setData();
      this.drawTiming = setInterval(() => {
        this.setData();
      }, this.$requestInterval);
    },

    // 辅助函数：将HSV颜色值转换为RGB
    hsvToRgb(h, s, v) {
      h /= 60;
      const c = v * s;
      const x = c * (1 - Math.abs((h % 2) - 1));
      let r, g, b;

      switch (Math.floor(h)) {
        case 0:
          (r = c), (g = x), (b = 0);
          break;
        case 1:
          (r = x), (g = c), (b = 0);
          break;
        case 2:
          (r = 0), (g = c), (b = x);
          break;
        case 3:
          (r = 0), (g = x), (b = c);
          break;
        case 4:
          (r = x), (g = 0), (b = c);
          break;
        default:
          (r = c), (g = 0), (b = x);
          break;
      }

      const m = v - c;
      [r, g, b] = [r + m, g + m, b + m].map((val) => Math.round(val * 255));

      return {
        rgbColor: `rgb(${r}, ${g}, ${b})`,
        rgbColorArea: `rgb(${r}, ${g}, ${b} ,0.2)`,
      };
    },

    generateDistinctColors(length) {
      // 固定饱和度和亮度以确保颜色鲜明
      const saturation = 0.9; // 饱和度高使得颜色更纯
      const value = 0.9; // 亮度高使得颜色不过暗

      // 色相范围为0到360，这里我们分配整个范围以最大化颜色对比
      const hueStep = 360 / length; // 确保每个颜色的色相间隔均匀

      let colors = [];
      for (let i = 0; i < length; i++) {
        // 计算当前颜色的色相值
        const hue = i * hueStep;

        // 使用HSV转RGB公式计算RGB值
        const rgb = this.hsvToRgb(hue, saturation, value);

        // 将RGB值添加到结果数组
        colors.push(rgb);
      }

      return colors;
    },

    setData() {
      this.cdata.x = [];
      this.cdata.series = [];

      const params = {
        cluster: this.$route.query.cluster,
      };

      getTaskDetailGPU(params)
        .then((response) => {
     
          const length = response.data.list.length;
          const distinctColors = this.generateDistinctColors(length);

          const series = response.data.list.map((element, index) => {
            // 生成对应数量的不重复颜色
            return {
              name: element.name,
              type: "line",
              smooth: true,
              symbol: "emptyCircle",
              symbolSize: 5,
              itemStyle: {
                normal: {
                  color: distinctColors[index].rgbColor,
                },
              },
              lineStyle: {
                normal: {
                  color: distinctColors[index].rgbColor,
                  width: 1,
                },
              },
              areaStyle: {
                normal: {
                  color: distinctColors[index].rgbColorArea,
                },
              },
              data: element.UT,
              lineSmooth: true,
             
            };
          });
         
          this.cdata.x = response.data.list[0].x || [];
          this.cdata.series = series;
        })
        .catch((error) => {
          console.error("There was an error!", error);
          // 处理错误
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>