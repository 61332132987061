<template>
  <div class="d-flex jc-center">
    <Chart :cdata="cdata" />
  </div>
</template>
  
  <script>
import Chart from "./chart.vue";
import { getNodeState } from "@/common/api/index.js";

export default {
  data() {
    return {
      drawTiming: null,
      cdata: {
        alloc: 0,
        ok: 0,
        fail: 0,
      },
    };
  },
  components: {
    Chart,
  },
  props: {
    cluster: {
      type: String,
      default: () => "",
    },
  },
  mounted() {
    this.drawTimingFn();
  },
  beforeDestroy() {
    clearInterval(this.drawTiming);
  },
  methods: {
    drawTimingFn() {
      this.setData();
      this.drawTiming = setInterval(() => {
        this.setData();
      }, this.$requestInterval);
    },
    setData() {
      const params = {
        cluster: this.$route.query.cluster,
      };
      getNodeState(params)
        .then((response) => {
       
          const { alloc, ok, fail, other } = response.data;

          const total = alloc + ok + fail + other;
          const allocRatio = ((alloc / total) * 100).toFixed(2);
          const okRatio = ((ok / total) * 100).toFixed(2);
          const failRatio = ((fail / total) * 100).toFixed(2);
          const otherRatio = ((other / total) * 100).toFixed(2);

          this.cdata.alloc = [allocRatio];
          this.cdata.ok = [okRatio];
          this.cdata.fail = [failRatio];
          this.cdata.other = [otherRatio];

          const allNode = alloc + ok + fail + other;
          const allocNode = alloc;
          this.$emit("setNum", allNode, allocNode);
        })
        .catch((error) => {
          console.error("There was an error!", error);
          // 处理错误
        });
    },
  },
};
</script>
  
  <style lang="scss" scoped>
</style>