<template>
  <div>
    <Echart
      :options="options"
      id="centreRightChart"
      height="140px"
      width="690px"
    ></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
  data() {
    return {
      options: {},
     
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        this.options = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              crossStyle: {
                color: "#999",
              },
            },
            position: ["50%", "50%"],
          },
          grid: {
            left: 16,
            right: 16,
            bottom: 16,
            top: 16,
            containLabel: true,
          },

          xAxis: {
            type: "category",
            position: "bottom",
            axisLine: true,
            axisLabel: {
              color: "#fff",
              fontSize: 12,
              // interval: 200, // 每隔3个类目显示一个标签
              fontWeight: 600,
              formatter: function (params) {
                return params.substring(10, 16);
              },
            },
            data: newData.x,
          },
          // 下方Y轴
          yAxis: {
            nameLocation: "end",
            nameGap: 24,
            nameTextStyle: {
              color: "#fff",
              fontSize: 14,
            },
            max: 100,
            splitNumber: 5,

            axisLine: {
              lineStyle: {
                opacity: 0,
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#fff",
                opacity: 0.1,
              },
            },
            axisLabel: {
              color: "#fff",
              fontSize: 12,
            },
          },
          series: newData.series,
        };
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
