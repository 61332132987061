<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <div class="header">
        <span class="title-text">{{ title }}监控</span>
        <span class="now-time"
          >{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span
        >

      
      </div>

      <div class="detail-body">
        <!-- 第一行数据 -->
        <div class="content">
          <dv-border-box-12>
            <centerLeft />
          </dv-border-box-12>
          <dv-border-box-12>
            <centerRight />
          </dv-border-box-12>
        </div>
        <!-- 第二行数据 -->
        <div class="bottom">
          <dv-border-box-12>
            <bottomLeft :cluster="cluster" />
          </dv-border-box-12>

          <dv-border-box-12>
            <bottomRight :cluster="cluster" />
          </dv-border-box-12>

          <dv-border-box-12>
            <bottomRight2 :cluster="cluster" />
          </dv-border-box-12>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import drawMixin from "../../utils/drawMixin";
import { formatTime } from "../../utils/index.js";
import centerLeft from "./centerLeft";
import centerRight from "./centerRight";
import bottomLeft from "./bottomLeft";
import bottomRight from "./bottomRight";
import bottomRight2 from "./bottomRight2";

export default {
  components: {
    centerLeft,
    centerRight,
    bottomLeft,
    bottomRight,
    bottomRight2,
  },
  mixins: [drawMixin],
  data() {
    return {
      title: "",
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
    };
  },
  created() {
    this.title = this.$route.query.title;
    this.cluster = this.$route.query.cluster;
  },
  mounted() {
    this.timeFn();
    this.cancelLoading();
  },
  beforeDestroy() {
    clearInterval(this.timing);
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH: mm: ss");
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000);
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
  },
};
</script>
  
  <style lang="scss" scoped>
@import "../../assets/scss/index.scss";
</style>
  