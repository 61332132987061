<template>
  <div id="bottomRight2">
    <div class="bg-color-black">
      <!-- 标题 -->
      <div class="header">
        <div class="header-line" style="margin-bottom: 2px"></div>
        <div class="header-title">集群信息</div>
        <div class="header-line" style="margin-top: 2px"></div>
      </div>

      <div class="content">
        <div class="item-header">
          <div class="left">项目</div>
          <div class="right">详情</div>
        </div>
        <div class="item-list">
          <div class="label">
            CPU节点 <br />({{ clusterInfo.cpuModel }})
          </div>
          <div class="value" v-html="clusterInfo.cpuNode"></div>
        </div>

        <div class="item-list">
          <div class="label">GPU节点 <br />({{ clusterInfo.gpuModel }})</div>
          <div class="value">{{ clusterInfo.gpuNode }}</div>
        </div>
        <div class="item-list">
          <div class="label">存储节点</div>
          <div class="value">{{ clusterInfo.storageNode }}</div>
        </div>
        <div class="item-list">
          <div class="label">作业系统</div>
          <div class="value">{{ clusterInfo.schedulerSystem }}</div>
        </div>
        <div class="item-list">
          <div class="label">联系方式</div>
          <div class="value">{{ clusterInfo.contact }}</div>
        </div>
        <div class="tag">前往使用</div>
        
      </div>
    </div>
  </div>
</template>
      
<script>
import { getClusterInfo } from "@/common/api";

export default {
  data() {
    return {
      clusterInfo: {},
    };
  },
  mounted() {
    console.log("this.$route.query.cluster ", this.$route.query.cluster);
    this.cluster = this.$route.query.cluster || "";
    this.getData();
  },
  methods: {
    getData() {
      // 获取任务状态
      const params = {
        cluster: this.$route.query.cluster,
      };
      getClusterInfo(params)
        .then((response) => {
          this.clusterInfo = response.data;
        })
        .catch((error) => {
          console.error("There was an error!", error);
          // 处理错误
        });
    },
  },
};
</script>
      
    <style lang="scss" scoped>
$box-width: 346px;
$box-height: 380px;
#bottomRight2 {
  padding: 6px;
  height: $box-height;
  width: $box-width;
  border-radius: 10px;
  position: relative;

  .header {
    font-family: YouSheBiaoTiHei, YouSheBiaoTiHei;
    padding: 16px 0;
    .header-line {
      width: 100%;
      height: 1px;
      background: linear-gradient(
        90deg,
        rgba(0, 152, 235, 0) 12%,
        #0098eb 52%,
        rgba(0, 152, 235, 0) 83%
      );
    }
    .header-title {
      color: #eff4ff;
      background: linear-gradient(
        90deg,
        rgba(0, 152, 235, 0) 0%,
        rgba(0, 152, 235, 0.5) 53%,
        rgba(0, 152, 235, 0) 100%
      );
      width: 100%;
      height: 36px;
      // margin: 20px 0;
      text-align: center;
      line-height: 36px;
      font-size: 22px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    .item-header {
      padding: 8px 0;
      width: 100%;
      background-color: rgba(106, 112, 124, 0.2);
      display: flex;
      font-size: 14px;
      font-weight: 500;
      .left {
        width: 30%;
        text-align: center;
      }
      .right {
        width: 70%;
        text-align: center;
      }
    }
    .item-list {
      width: 100%;
      display: flex;
      font-size: 12px;
      font-weight: 500;
      border-bottom: 1px solid rgba(106, 112, 124, 0.2);
      padding: 6px;

      .label {
        width: 30%;
        white-space: pre-wrap;
        display: flex;
        align-items: center;
      }
      .value {
        width: 70%;
        white-space: pre-wrap;
      }
    }

    .tag {
        position: absolute;
        bottom: 40px;
        right: 10px;
        background-image: url("../../assets/icon1.png");
        background-size: cover;
        width: 81px;
        height: 31px;
        line-height: 31px;
        text-align: center;
        font-weight: 500;
        font-size: 12px;
        color: #ffffff;
        margin-left: 8px;
        cursor: pointer;
      }
  }

  .bg-color-black {
    height: $box-height - 12px;
    width: $box-width;
    border-radius: 10px;
  }
}
</style>
      